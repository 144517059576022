const artistsList = [
  {
    name: "Jay",
    files: "Jay",
    fullName: "Jay Rascal",
    location: "Waikiki",
    location2: "Kalakaua",
    pic: "/images/artists/Jay.jpg",
    url: "https://sacredartwaikiki.com/gallery/jay",
    url2: "https://sacredarthonolulu.com/gallery/jay",
  },
  {
    name: "Josh",
    files: "Josh",
    fullName: "Josh Crowell",
    location: "Kapolei",
    pic: "/images/artists/josh-pic.jpg",
    url: "https://sacredartkapolei.com/gallery/josh",
  },
  {
    name: "Blaine",
    files: "Blaine",
    fullName: "Blaine Reum",
    location: "Kalakaua",
    pic: "/images/artists/blaine-pic.jpg",
    url: "https://sacredarthonolulu.com/gallery/blaine",
  },
  {
    name: "James",
    files: "James",
    fullName: "James",
    location: "Kalakaua",
    pic: "/images/artists/James.jpeg",
    url: "https://sacredarthonolulu.com/gallery/james",
  },
  {
    name: "Marco",
    files: "Marco",
    fullName: "Marco Perez",
    location: "Kalakaua",
    pic: "/images/artists/Marco.jpeg",
    url: "https://sacredarthonolulu.com/gallery/marco",
  },
  {
    name: "Brian",
    files: "Brian",
    fullName: "Brian Mau",
    location: "Kailua",
    pic: "/images/artists/brian.jpg",
    url: "https://sacredartkailua.com/gallery/brian",
  },
  {
    name: "Julian",
    files: "Julian",
    fullName: "Julian Altier",
    location: "Kapolei",
    location2: "Kailua",
    pic: "/images/artists/Julian.jpeg",
    url: "https://sacredartkapolei.com/gallery/julian",
    url2: "https://sacredartkailua.com/gallery/julian",
  },
  {
    name: "Nate R",
    files: "NateR",
    fullName: "Nate Rose",
    location: "Waikiki",
    pic: "/images/artists/Nate.jpeg",
    url: "https://sacredartwaikiki.com/gallery/nate",
  },
  {
    name: "Kluch",
    files: "shawn",
    fullName: "Shawn Hutto",
    location: "Kapolei",
    pic: "/images/artists/shawn.jpg",
    url: "https://sacredartkapolei.com/gallery/kluch",
  },
  {
    name: "Shaye",
    files: "Shaye",
    fullName: "Shaye Iwanuma",
    location: "Kalakaua",
    pic: "/images/artists/shaye.jpg",
    url: "https://sacredarthonolulu.com/gallery/shaye",
  },
  {
    name: "Mako",
    files: "Mako",
    fullName: "Mako Zane",
    location: "Kalakaua",
    pic: "/images/artists/mako.jpg",
    url: "https://sacredarthonolulu.com/gallery/mako",
  },
  {
    name: "Mino",
    files: "Mino",
    fullName: "Mino Kaneko",
    location: "Kapolei",
    location2: "Kailua",
    pic: "/images/artists/mino.jpg",
    url: "https://sacredartkapolei.com/gallery/mino",
    url2: "https://sacredartkailua.com/gallery/mino",
  },
  {
    name: "Matt",
    files: "Matt",
    fullName: "Matt Towns",
    location: "Waikiki",
    pic: "/images/artists/Matt.jpg",
    url: "https://sacredartwaikiki.com/gallery/matt",
  },
  {
    name: "Kliff",
    files: "Kliff",
    fullName: "Clifford Manners",
    location: "Kapolei",
    pic: "/images/artists/Kliff.jpg",
    url: "https://sacredartkapolei.com/gallery/kliff",
  },
  {
    name: "Sean",
    files: "Sean",
    fullName: "Sean Martin",
    location: "Kalakaua",
    pic: "/images/artists/Sean.jpg",
    url: "https://sacredarthonolulu.com/gallery/sean",
  },
  {
    name: "Summer K",
    files: "summer",
    fullName: "Summer Kaay",
    location: "Kailua",
    pic: "/images/artists/summer.jpg",
    url: "https://sacredartkailua.com/gallery/summer k",
  },
  {
    name: "Brags",
    files: "Brags",
    fullName: "Brags",
    location: "Waikiki",
    pic: "/images/artists/brags.jpg",
    url: "https://sacredartwaikiki.com/gallery/brags",
  },
  {
    name: "Kaitlin",
    files: "kaitlin",
    fullName: "Kaitlin B.",
    location: "Kalakaua",
    pic: "/images/artists/Kaitlin.jpg",
    url: "https://sacredarthonolulu.com/gallery/kaitlin",
  },
  {
    name: "Kamaile",
    files: "kamaile",
    fullName: "Kamaile",
    location: "Kailua",
    location2: "Kapolei",
    pic: "/images/artists/Kamaile.jpg",
    url: "https://sacredartkailua.com/gallery/kamaile",
    url2: "https://sacredartkapolei.com/gallery/kamaile",
  },
  {
    name: "Tweety",
    files: "Tweety",
    fullName: "Tweety Higgins",
    location: "Waikiki",
    pic: "/images/artists/tweety.jpg",
    url: "https://sacredartwaikiki.com/gallery/tweety",
  },
  {
    name: "Hector",
    files: "Hector",
    fullName: "Hector Moreno",
    location: "Kalakaua",
    pic: "/images/artists/hector.jpg",
    url: "https://sacredarthonolulu.com/gallery/hector",
  },
  {
    name: "Dan",
    files: "Dan",
    fullName: "Dan",
    location: "Kalakaua",
    pic: "/images/artists/dan.jpg",
    url: "https://sacredarthonolulu.com/gallery/dan",
  },
  {
    name: "Nate B",
    files: "NateB",
    fullName: "Nate Brothers",
    location: "Kalakaua",
    pic: "/images/artists/nateB.jpg",
    url: "https://sacredarthonolulu.com/gallery/nate",
  },
  {
    name: "Anthony",
    files: "Anthony",
    fullName: "Anthony Tran",
    location: "Kapolei",
    pic: "/images/artists/anthony.jpg",
    url: "https://sacredartkapolei.com/gallery/anthony",
  },
  {
    name: "Ben",
    files: "Ben",
    fullName: "Ben Trail",
    location: "Kapolei",
    pic: "/images/artists/ben.jpg",
    url: "https://sacredartkapolei.com/gallery/ben",
  },
  {
    name: "Diego",
    files: "Diego",
    fullName: "Diego Palmares",
    location: "Kapolei",
    pic: "/images/artists/diego.jpg",
    url: "https://sacredartkapolei.com/gallery/diego",
  },
  {
    name: "Curtis",
    files: "Curtis",
    fullName: "Curtis Haggerty",
    location: "Waikiki",
    pic: "/images/artists/curtis.jpg",
    url: "https://sacredartwaikiki.com/gallery/curtis",
  },
  {
    name: "Laura",
    files: "Laura",
    fullName: "Laura",
    location: "Kalakaua",
    pic: "/images/artists/laura.jpg",
    url: "https://sacredarthonolulu.com/gallery/laura",
  },
  {
    name: "Tait",
    files: "tait",
    fullName: "Tait Nelson",
    location: "Kailua",
    pic: "/images/artists/tait.jpg",
    url: "https://sacredartkailua.com/gallery/tait",
  },
  {
    name: "Alex",
    files: "12Alex",
    fullName: "Alex",
    location: "Kailua",
    pic: "/images/artists/alex2.jpg",
    url: "https://sacredartkailua.com/gallery/alex",
  },
  {
    name: "Karl",
    files: "Karl",
    fullName: "Karl",
    location: "Waikiki",
    pic: "/images/artists/karl.jpg",
    url: "https://sacredartwaikiki.com/gallery/karl",
  },
  {
    name: "Maddie (Piercer)",
    files: "Maddie",
    fullName: "Maddie",
    location: "Waikiki",
    pic: "/images/artists/maddie.jpg",
    url: "https://sacredartwaikiki.com/gallery/maddie (Piercer)",
  },
  {
    name: "Lexi",
    files: "Lexi",
    fullName: "Lexi",
    location: "Kailua",
    location2: "Kapolei",
    pic: "/images/artists/lexi.jpg",
    url: "https://sacredartkailua.com/gallery/lexi",
    url2: "https://sacredartkapolei.com/gallery/lexi",
  },
  {
    name: "Jared",
    files: "Jared",
    fullName: "Jared",
    location: "Waikiki",
    pic: "/images/artists/jared.jpg",
    url: "https://sacredartwaikiki.com/gallery/jared",
  },
  {
    name: "John",
    files: "JohnM",
    fullName: "John Maxwell",
    location: "Kapolei",
    location2: "Kailua",
    pic: "/images/artists/johnmaxwell.jpg",
    url: "https://sacredartkapolei.com/gallery/john",
    url2: "https://sacredartkailua.com/gallery/john",
  },
  {
    name: "Smokey",
    files: "Smokey",
    fullName: "Smokey",
    location: "Kalakaua",
    pic: "/images/artists/smokey.jpg",
    url: "https://sacredarthonolulu.com/gallery/smokey",
  },
  {
    name: "Darin",
    files: "Darin",
    fullName: "Darin Shiu",
    location: "Kapolei",
    pic: "/images/artists/darin.jpg",
    url: "https://sacredartkapolei.com/gallery/darin",
  },
  {
    name: "Sasha",
    files: "sasha",
    fullName: "Sasha Beringer",
    location: "Kailua",
    pic: "/images/artists/sasha.jpg",
    url: "https://sacredartkailua.com/gallery/sasha",
  },
];

export default artistsList;
