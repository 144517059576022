import { Helmet } from "react-helmet";
import turtles from "../assets/images/turtles.png";

function Piercing() {
  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo Hawaii | Piercing</title>
      </Helmet>
      <div>
        <section id="piercing" className="coffee_area mt-60 mb-30">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section_title text-center pb-30">
                  <h4 className="title">Piercing</h4>
                  <img className="turtles" alt="turtles" src={turtles} />
                  <h4 className="fst-italic mt-4">
                    All prices include stainless steel jewelry. There is an
                    additional up charge of $20-$40 for titanium jewelry
                    depending on the piece
                  </h4>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                {/* <div className="col-md-6">
                  <div className="servicesBorders">
                    <h3>WAIKIKI</h3>
                    <h6 className="mt-20 mb-10">EAR</h6>
                    <p>
                      Earlobe single : $60
                      <br />
                      Earlobe (both): $100
                      <br />
                      Tragus / Anti-Tragus : $80
                      <br />
                      Rook: $80
                      <br />
                      Conch: $80
                      <br />
                      Industrial: $100
                      <br />
                      Daith: $80
                      <br />
                      Orbital : $80
                      <br />
                      Helix / Anti-Helix: $60
                      <br />
                      Snug: $80
                    </p>
                    <h6 className="mt-20 mb-10">Facial & Oral</h6>
                    <p>
                      Nostril: $60
                      <br />
                      Eyebrow: $80
                      <br />
                      Tongue: $80
                      <br />
                      Septum: $100
                      <br />
                      Labret: $80
                      <br />
                      Lip: $80
                      <br />
                      Medusa: $100
                      <br />
                      Monroe: $80
                      <br />
                      Tooth gems: $60 / Gold 18k: $120
                    </p>
                    <h6 className="mt-20 mb-10">Body</h6>
                    <p>
                      Navel: $80
                      <br />
                      Single Nipple: $80 / $120 for set
                    </p>
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="servicesBorders">
                    <h3 className="mb-20">PRICES</h3>
                    <h6 className="mt-20 mb-10">EAR</h6>
                    <p>
                      Earlobe single : $60
                      <br />
                      Earlobe (both): $100
                      <br />
                      Tragus / Anti-Tragus : $80
                      <br />
                      Rook: $80
                      <br />
                      Conch: $80
                      <br />
                      Industrial: $100
                      <br />
                      Daith: $80
                      <br />
                      Orbital : $80
                      <br />
                      Helix / Anti-Helix: $60
                      <br />
                      Snug: $80
                    </p>
                    <h6 className="mt-20 mb-10">Facial & Oral</h6>
                    <p>
                      Nostril: $60
                      <br />
                      Eyebrow: $80
                      <br />
                      Tongue: $80
                      <br />
                      Septum: $100
                      <br />
                      Labret: $80
                      <br />
                      Lip: $80
                      <br />
                      Medusa: $100
                      <br />
                      Monroe: $80
                      <br />
                      Tooth Gems (Honolulu): $60 / Gold 18k: $120
                    </p>
                    <h6 className="mt-20 mb-10">Body</h6>
                    <p>
                      Navel: $80
                      <br />
                      Single Nipple: $80 / $120 for set
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Piercing;
